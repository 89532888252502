type CustomThunkResult<R> = {
  data: R | undefined,
  isUninitialized: boolean,
  isLoading: boolean,
  isFetching: boolean,
  isSuccess: boolean,
  isError: boolean,
};

const uninitializedCustomThunkResult = <R> (): CustomThunkResult<R> => ({
  data: undefined,
  isUninitialized: true,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
});

const successCustomThunkResult = <R> (data: R): CustomThunkResult<R> => ({
  data,
  isUninitialized: true,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
});

const fetchingCustomThunkResult = <R> (previous: CustomThunkResult<R>): CustomThunkResult<R> => ({
  ...previous,
  isSuccess: false,
  isError: false,
  isLoading: true,
  isFetching: true,
});

const rejectedCustomThunkResult = <R> (): CustomThunkResult<R> => ({
  data: undefined,
  isUninitialized: true,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  isError: true,
});

export type { CustomThunkResult };
export {
  uninitializedCustomThunkResult, successCustomThunkResult, fetchingCustomThunkResult, rejectedCustomThunkResult,
};
