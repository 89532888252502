import { Dropdown } from 'semantic-ui-react';
import React from 'react';
import { selectNotes } from '../note/noteSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { when } from '../../app/util';
import { openView, selectApp } from './appSlice';
import { Note } from '../note/types';

const Recent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { recents } = useAppSelector(selectApp);

  const onRecentClick = (note: Note) => {
    dispatch(openView(note.id));
  };

  const notes = useAppSelector((state) => selectNotes(state));
  const items = recents.map((id) => {
    const note = notes.find((n) => n.id === id);
    return when(
      note,
      (n) => (<Dropdown.Item key={id} text={n.title} onClick={() => onRecentClick(n)} />),
      () => (<Dropdown.Item key={id}>Loading...</Dropdown.Item>),
    );
  });

  return (
    <Dropdown text="Recent" simple item>
      <Dropdown.Menu>
        {items}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Recent;
