// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
import React from 'react';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import { unified } from 'unified';
import * as prod from 'react/jsx-runtime';
import remarkRehype, { Options } from 'remark-rehype';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import deepmerge from 'deepmerge';
import { Schema } from 'hast-util-sanitize';
import rehypeReact, { Options as RehypeReactOptions } from 'rehype-react';
import { Dictionary } from '@reduxjs/toolkit';
import remarkMentions from './remark-mentions';
import { Note, RenderMode } from '../feature/note/types';
import { listItem } from './handlers/listitem';
import link from './handlers/link';
import { applyCustomMerge } from './attribute-merge';
import { Mention } from '../feature/mention/types';

const mergedSchema = deepmerge(defaultSchema, {
  ancestors: {
    button: ['div'],
    i: ['button'],
  },
  tagNames: ['button', 'span', 'form'],
  attributes: {
    input: ['data-task', 'data-task-id', 'data-note-id', 'data-task-completed'],
    li: ['className'],
    a: [['className', 'mention'], "title" ],
    div: ['className'],
    i: ['className'],
    button: ['className'],
    span: ['className', "title"],
    form: ['action', 'target'],
  },
  protocols: {
    href: ['urn'],
  }
}, {
  customMerge: applyCustomMerge(['a'])
});

const customSchema = {
  ...mergedSchema,
  required: {},
} as Schema;

// @ts-expect-error: the react types are missing.
const rehypeProdOptions: RehypeReactOptions = {Fragment: prod.Fragment, jsx: prod.jsx, jsxs: prod.jsxs };
// const rehypeDevOptions = { Fragment: dev.Fragment, jsxDEV: dev.jsxDEV, development: true }

const markdownToJsx
  = async (note: Note, mentions: Dictionary<Mention>, renderMode: RenderMode): Promise<JSX.Element> => {

  const remarkRehypeOptions = {
    handlers: {
      listItem: listItem(note, renderMode),
      link: link(mentions),
    },
  } as Options;

  const file = await unified()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkMentions)
    .use(remarkRehype, remarkRehypeOptions)
    .use(rehypeSanitize, customSchema)
    .use(rehypeReact, rehypeProdOptions)
    .process(note.content);
  return file.result;
};

export default markdownToJsx;
