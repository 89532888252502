import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import appReducer from '../feature/app/appSlice';
import feedsReducer from '../feature/feeds/feedsSlice';
import noteReducer from '../feature/note/noteSlice';
import taskReducer from '../feature/task/taskSlice';
import quickSearchReducer from '../feature/quicksearch/quickSearchSlice';
import contactReducer from '../feature/contact/contactSlice';
import { noteApi } from '../feature/note/noteApi';
import { feedApi } from '../feature/feeds/feedApi';
import { taskApi } from '../feature/task/taskApi';
import identityApi from '../feature/user/identityapi';
import { contactApi } from '../feature/contact/contactApi';
import { mentionApi } from '../feature/mention/mentionApi';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = configureStore({
  reducer: {
    app: appReducer,
    feeds: feedsReducer,
    note: noteReducer,
    task: taskReducer,
    quickSearch: quickSearchReducer,
    contact: contactReducer,
    [noteApi.reducerPath]: noteApi.reducer,
    [taskApi.reducerPath]: taskApi.reducer,
    [feedApi.reducerPath]: feedApi.reducer,
    [identityApi.reducerPath]: identityApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [mentionApi.reducerPath]: mentionApi.reducer,
  },
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    noteApi.middleware,
    feedApi.middleware,
    taskApi.middleware,
    identityApi.middleware,
    contactApi.middleware,
    mentionApi.middleware,
  ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export { store };
