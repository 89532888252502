import './Note.css';
import './NoteCard.css';
import { createElement, Fragment, KeyboardEvent, useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import _ from 'lodash';
import StatusIndicator, { mapIndicatorStatus } from '../../components/StatusIndicator';
import { useAppDispatch } from '../../app/hooks';
import { openEdit, openView } from '../app/appSlice';
import { useDeleteNoteMutation, useGetNoteQuery } from './noteApi';
import { formatUtcDate } from '../../app/util';
import markdownToJsx from '../../app/remark';
import { useGetMentionsQuery } from '../mention/mentionApi';

type NoteSize = 'x-small' | 'small' | 'medium' | 'large' | 'x-large';

const sizeOf3 = (content: string): NoteSize => {
  if (content === undefined || content.length <= 366) {
    return 'small';
  }
  if (content.length < 848) {
    return 'medium';
  }
  return 'large';
}

const NoteCard = (props: { noteId: string }): JSX.Element => {
  const dispatch = useAppDispatch();

  const { noteId } = props;
  const { data: note, isSuccess, isLoading, isError } = useGetNoteQuery(noteId);
  const { data: mentionsList } = useGetMentionsQuery(note?.mentions || [], { skip: !note || _.isEmpty(note.mentions) });

  const [deleteNote] = useDeleteNoteMutation();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [preview, setPreview] = useState<JSX.Element>(createElement(Fragment));

  useEffect(() => {
    (async function renderPreview() {
      if (note && (_.isEmpty(note.mentions) || mentionsList)) {
        const fragment = await markdownToJsx(note, _.keyBy(mentionsList, 'id'), 'Preview');
        setPreview(fragment);
      }
    })();
  }, [note, mentionsList]);

  if (!isSuccess) {
    return (
      <div className="note preview loading">
        <StatusIndicator status={mapIndicatorStatus(isSuccess, isLoading, isError)} />
      </div>
    );
  }

  const { created } = note.meta;

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.key === 'v') {
      dispatch(openView(note.id));
    }
  };

  const onDeleteConfirmed = () => {
    deleteNote(note.id);
  };

  const noteSize = sizeOf3(note.content);
  return (
    <div className={`${noteSize} note preview`}>
      <div
        className="header"
        tabIndex={0}
        role="button"
        onClick={() => dispatch(openView(note.id))}
        onKeyDown={onKeyDown}
        aria-label="Open"
      >
        <div className="date">{formatUtcDate(created)}</div>
      </div>

      {/* eslint-disable */}
      <div
        className="body"
        tabIndex={0}
        role="button"
        onClick={() => dispatch(openView(note.id))}
        onKeyDown={onKeyDown}
        aria-label="Open">
        {preview}
      </div>
      {/* eslint-enable */}

      <div className="footer">
        <div className="actions">
          <Button icon="edit" onClick={() => dispatch(openEdit(note.id))} />
          <Modal
            onOpen={() => setDeleteConfirmOpen(true)}
            onClose={() => setDeleteConfirmOpen(false)}
            open={deleteConfirmOpen}
            trigger={<Button icon="trash" />}
            header="Delete note?"
            content="You are deleting this note permanently."
            actions={[
              'Back',
              {
                key: 'enter',
                content: 'Delete',
                positive: false,
                className: 'red',
                onClick: onDeleteConfirmed,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default NoteCard;
