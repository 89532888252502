import _ from 'lodash';
import { Button, Dimmer, Form, Header, Loader, Modal, Segment, Table } from 'semantic-ui-react';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setContactsOpen } from '../app/appSlice';
import ContactRow from './ContactRow';
import { useDeleteContactMutation, useLazyGetAllContactsQuery, useLazySearchContactsQuery } from './contactApi';
import './ContactsModal.css';
import { cancelDelete, openCreate, selectContact } from './contactSlice';
import ContactCreateForm from './ContactCreateForm';
import ContactEditor from './ContactEditor';

type ContactsModalProps = {
  open: boolean;
};

const loadingSegment = () => (
  <Table.Row>
    <Table.Cell colSpan="5">
      <Segment placeholder>
        <Dimmer active inverted>
          <Loader inverted content='Loading' />
        </Dimmer>
      </Segment>
    </Table.Cell>
  </Table.Row>
  )

const emptySegment = () => (
  <Table.Row>
    <Table.Cell colSpan="5">
      <Segment placeholder>
        <Header textAlign="center">
          No contacts found.
        </Header>
      </Segment>
    </Table.Cell>
  </Table.Row>
);

const ContactsModal = (props: ContactsModalProps) => {
  const dispatch = useAppDispatch();
  const [getAllContacts, allContactsResult] = useLazyGetAllContactsQuery();
  const [searchContacts, searchContactsResult] = useLazySearchContactsQuery();
  const [deleteContact] = useDeleteContactMutation();
  const [query, setQuery] = useState('');
  const { create: isCreateOpen, contacts, edit: editedId, delete: delete1, } = useAppSelector(selectContact);

  const onSubmit = useCallback(() => {
    if (query === '') {
      getAllContacts();
    } else {
      searchContacts(query);
    }
  }, [query]);

  const doDelete = useCallback(() => {
    if (delete1 !== undefined) {
      deleteContact(delete1.contact);
    }
  }, [delete1]);

  useEffect(() => {
    getAllContacts();
  }, []);

  const result = query === '' ? allContactsResult : searchContactsResult;
  const {
    isFetching
  } = result;

  const createForm = isCreateOpen ? (<ContactCreateForm/>) : null;

  const content = _.cond([
    [() => isFetching, () => loadingSegment()],
    [() => contacts.length === 0, () => emptySegment()],
    [_.stubTrue, () => (<>
      {contacts.map((contact) => contact.id === editedId ?
        (<ContactEditor key={`e${contact.id}`}  contact={contact} />) :
        (<ContactRow key={contact.id} contact={contact} />))}</>)],
  ])();

  return (
    <div className="contacts">
      <Modal open={props?.open} className="ContactsModal">
        <Modal.Header>
          <div>
            <Header size="large">Contacts</Header>
          </div>
          <div>
            <Form onSubmit={onSubmit}>
              <Form.Input
                icon="search"
                content={query}
                onChange={(_event, data) => setQuery(data.value)}
                placeholder="Search contacts..."
              />
            </Form>
            <div className="actions">
              <Button icon="plus" onClick={() => dispatch(openCreate())} />
              <Button icon="close" onClick={() => dispatch(setContactsOpen(false))} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          {createForm}
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Mention</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Display name</Table.HeaderCell>
                <Table.HeaderCell>E-mail</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>{content}</Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
      <Modal
        open={delete1 !== undefined}>
        <Modal.Header>
          Delete contact?
        </Modal.Header>
        <Modal.Content>
          You are deleting contact: <strong>@{delete1?.contact.mentionKey}</strong>.
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => dispatch(cancelDelete())}>Back</Button>
          <Button onClick={doDelete} negative className="red">Delete</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default ContactsModal;
