import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SearchResultProps } from 'semantic-ui-react';
import _ from 'lodash';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Note, NoteUpdate } from './types';
import { formatUtcDate } from '../../app/util';
import { taskApi } from '../task/taskApi';
import { FeedQuery } from '../feeds/feedApi';
import { addAuthorization } from '../../auth';

const mapQuickSearchResults = (results: SearchResultProps[]): SearchResultProps[] =>
  _.map(results, (r) => ({ ...r, description: `Last modified: ${formatUtcDate(r.lastModified)}` }));

const updateMyTasks = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  dispatch(taskApi.util.invalidateTags([{ type: 'task', id: 'MYTASKS' }]));
  return dispatch(taskApi.endpoints.getMyTasks.initiate());
};

type OnQueryStartedArg = {
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  queryFulfilled: Omit<Promise<unknown>, 'then' | 'catch'>;
};

const updateMyTasksOnQueryStarted = async (_queryArg: unknown, { dispatch, queryFulfilled }: OnQueryStartedArg) => {
  await queryFulfilled;
  updateMyTasks(dispatch);
};

type ApiError = {
  error: {
    readonly status: number;
  }
}

export const noteApi = createApi({
  reducerPath: 'noteApi',
  tagTypes: ['note'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: addAuthorization,
  }),
  endpoints: (build) => ({
    getNote: build.query<Note, string>({
      query: (id) => `/note/${id}`,
      providesTags: (result) => [{ type: 'note' as const, id: result?.id }],
    }),
    saveNote: build.mutation<Note, NoteUpdate>({
      query: (note) => ({
        url: `/note/${note.id}`,
        method: 'PUT',
        body: { id: note.id, content: note.content },
        headers: [['If-Match', `"${note.digest}"`]],
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedNote } = await queryFulfilled;
          dispatch(noteApi.util?.upsertQueryData('getNote', updatedNote.id, updatedNote));
        } catch (ex) {
          const { error } = ex as ApiError;
          if (error.status === 412) {
            dispatch(noteApi.util.invalidateTags([{ type: 'note', id: queryArg.id }]));
          }
        }
        updateMyTasks(dispatch);
      },
    }),
    createNote: build.query<Note, Pick<Note, 'content'>>({
      query: (note: Pick<Note, 'content'>) => ({
        url: '/note/',
        method: 'POST',
        body: note,
      }),
      providesTags: (result) => [{ type: 'note' as const, id: result?.id }],
      onQueryStarted: updateMyTasksOnQueryStarted,
    }),
    deleteNote: build.mutation<void, string>({
      query: (noteId) => ({
        url: `/note/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'note' as const, id }],
      onQueryStarted: updateMyTasksOnQueryStarted,
    }),
    getManyNotes: build.query<Note[], string[]>({
      query: (noteIds: string[]) => ({
        url: '/note/',
        method: 'GET',
        params: {
          'id[]': noteIds,
        },
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          const { data: notes } = await queryFulfilled;
          notes.forEach((note) => dispatch(noteApi.util?.upsertQueryData('getNote', note.id, note)));
        } catch {
          // Nothing to do
        }
      },
    }),
    search: build.query<string[], FeedQuery>({
      query: ({ searchQuery }) => ({
        url: '/note/search',
        params: {
          query: searchQuery,
        },
        method: 'GET',
      }),
      providesTags: () => [{ type: 'note' as const, id: 'SEARCH' }],
    }),
    quickSearch: build.query<SearchResultProps[], string>({
      query: (query) => ({
        url: '/note/quickSearch',
        params: {
          query,
        },
        method: 'GET',
      }),
      transformResponse: mapQuickSearchResults,
      providesTags: () => [{ type: 'note' as const, id: 'QUICKSEARCH' }],
    }),
  }),
});

export const {
  useGetNoteQuery,
  useLazyGetNoteQuery,
  useSaveNoteMutation,
  useLazyCreateNoteQuery,
  useDeleteNoteMutation,
  useLazyQuickSearchQuery,
  useGetManyNotesQuery,
  useLazyGetManyNotesQuery,
} = noteApi;
