import {
  Button, Checkbox, Dropdown, Icon,
} from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { noteIdsFromMyTasks, Task, TaskId } from './types';
import { updateTask } from './task';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getMyTasksWithNotes, selectMyTasks } from './taskSlice';
import { selectNotesById } from '../note/noteSlice';
import { Note } from '../note/types';
import { adjustHeight, formatLocalDate } from '../../app/util';
import './MyTasksMenu.css';
import { openView } from '../app/appSlice';
import { AppDispatch } from '../../app/store';

const taskItem = (task: Task): JSX.Element | null => {
  const taskItemClicked = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.preventDefault();
    e.currentTarget.disabled = true;

    updateTask(task.noteId, task.id, !task.complete);
  };

  const classNames = ['task', 'input'];
  if (task.complete) {
    classNames.push('complete');
  }
  return (
    <Dropdown.Item key={task.id} className={classNames.join(' ')}>
      <Checkbox label={task.description} checked={task.complete} onClick={taskItemClicked} />
      { task.due && (<div className="date">{formatLocalDate(task.due)}</div>) }
    </Dropdown.Item>
  );
};

const noteHeader = (section: string, note: Note, dispatch: AppDispatch) => (
  <Dropdown.Header key={`${section}_${note.id}`} className="note">
    {note.title}
    {' '}
    <Button icon onClick={() => dispatch(openView(note.id))} size="tiny" compact basic alt="Jump to note">
      <Icon name="share" />
    </Button>
  </Dropdown.Header>
);

const taskItemSection = (
  section: string,
  sectionLabel: string,
  taskIds: TaskId[],
  tasks: Map<string, Task>,
  noteMap: Map<string, Note>,
  dispatch: AppDispatch,
) => {
  const items = [];
  items.push(<Dropdown.Header key={section}>{sectionLabel}</Dropdown.Header>);

  let lastNoteId = '';
  taskIds.forEach((taskId) => {
    const task = tasks.get(taskId.id);
    const note = noteMap.get(taskId.noteId);

    if (task && note) {
      if (task.noteId !== lastNoteId) {
        items.push(noteHeader(section, note, dispatch));
        lastNoteId = task.noteId;
      }

      items.push(taskItem(task));
    }
  });

  return items;
};

const calculateHeight = (windowHeight: number) => windowHeight - 100;

const MyTasksMenu = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [maxHeight, setMaxHeight] = useState(calculateHeight(window.innerHeight));
  useEffect(adjustHeight(maxHeight, setMaxHeight, calculateHeight));

  const {
    data: myTasks,
    isLoading,
  } = useAppSelector((state) => selectMyTasks(state));

  useEffect(() => {
    dispatch(getMyTasksWithNotes());
  }, []);

  const notes = useAppSelector((state) => selectNotesById(state, noteIdsFromMyTasks(myTasks)));

  const items = [];
  if (myTasks) {
    const noteMap = new Map<string, Note>();
    notes.forEach((note) => noteMap.set(note.id, note));

    const taskMap = new Map<string, Task>();
    _.flatten(notes
      .filter((note) => note !== undefined)
      .map((note) => (note as Note).tasks))
      .forEach((task) => taskMap.set(task.id, task));

    items.push(taskItemSection('today', 'Today', myTasks.today, taskMap, noteMap, dispatch));
    items.push(<Dropdown.Divider key="thisWeekDivider" />);
    items.push(taskItemSection('thisWeek', 'This week', myTasks.thisWeek, taskMap, noteMap, dispatch));
    items.push(<Dropdown.Divider key="theRestDivider" />);
    items.push(taskItemSection('theRest', 'The rest', myTasks.theRest, taskMap, noteMap, dispatch));
  }

  return (
    <Dropdown text="My tasks" item loading={isLoading} simple className="my-tasks">
      <Dropdown.Menu style={{ maxHeight }}>
        {items.length > 0 ? items : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MyTasksMenu;
