import _ from 'lodash';
import { createEntityAdapter, createSlice, EntityAdapter } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { Note, NoteState } from './types';
import { noteApi } from './noteApi';

const noteAdapter: EntityAdapter<Note> = createEntityAdapter();

const initialState: NoteState = {
  ids: [],
  entities: {},
};

export const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => builder
    .addMatcher(noteApi.endpoints.getNote.matchFulfilled, (state, action) => {
      noteAdapter.upsertOne(state, action.payload);
    })
    .addMatcher(noteApi.endpoints.getManyNotes.matchFulfilled, (state, action) => {
      noteAdapter.upsertMany(state, action.payload);
    })
    .addMatcher(noteApi.endpoints.saveNote.matchFulfilled, (state, action) => {
      noteAdapter.upsertOne(state, action.payload);
    })
    .addMatcher(noteApi.endpoints.createNote.matchFulfilled, (state, action) => {
      noteAdapter.upsertOne(state, action.payload);
    })
    .addMatcher(noteApi.endpoints.deleteNote.matchFulfilled, (state, action) => {
      noteAdapter.removeOne(state, _.toString(action.meta.arg.originalArgs));
    }),
});

export default noteSlice.reducer;

export const {
  selectAll: selectNotes,
  selectById: selectNoteById,
} = noteAdapter.getSelectors((state: RootState) => state.note);

export const selectNotesById = (state: RootState, ids: string[]): Note[] => {
  const notes = ids.map((id) => selectNoteById(state, id));
  const filtered = notes.filter((n) => n !== undefined) as Note[];

  if (filtered.length < notes.length) {
    return [];
  }

  return filtered;
};
