import { createSlice } from '@reduxjs/toolkit';
import QuickSearchState from './types';
import type { RootState } from '../../app/store';
import { noteApi } from '../note/noteApi';

const initialState: QuickSearchState = {
  loading: false,
  error: false,
  results: [],
};

export const quickSearchSlice = createSlice({
  name: 'quickSearch',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    .addMatcher(noteApi.endpoints.quickSearch.matchPending, (state) => ({
      ...state,
      loading: true,
      error: false,
    }))
    .addMatcher(noteApi.endpoints.quickSearch.matchRejected, (state) => ({
      ...state,
      loading: false,
      error: true,
    }))
    .addMatcher(noteApi.endpoints.quickSearch.matchFulfilled, (state, action) => ({
      ...state,
      loading: false,
      results: action.payload,
    })),
});

export const selectQuickSearch = (state: RootState): QuickSearchState => state.quickSearch;
export default quickSearchSlice.reducer;
