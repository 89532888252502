import { Button, Modal } from 'semantic-ui-react';
import './Note.css';
import './NoteEdit.css';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/theme-tomorrow';
import { setCompleters } from "ace-builds/src-noconflict/ext-language_tools";
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import _ from 'lodash';
import { useLocalStorage } from 'react-use';
import { Ace } from 'ace-builds';
import { useAppDispatch } from '../../app/hooks';
import { closeEdit, closeEditOpenView } from '../app/appSlice';
import { useGetNoteQuery, useLazyCreateNoteQuery, useSaveNoteMutation } from './noteApi';
import { adjustHeight } from '../../app/util';
import {
  calculateHeight,
  clearEditorState,
  createEditor,
  EDITOR_STATE_KEY,
  EditorState,
  EMPTY_EDITOR_STATE,
  loadNoteAndState,
  SavingState,
} from './editor';
import { useGetMentionCompletionsQuery } from '../mention/mentionApi';

type NoteEditProps = {
  noteId: string | undefined,
};

const NoteEdit = (props: NoteEditProps): JSX.Element | null => {
  const { noteId: propsNoteId } = props;
  const dispatch = useAppDispatch();
  const [createNote] = useLazyCreateNoteQuery();
  const [saveNote] = useSaveNoteMutation();

  // Layout state
  const [height, setHeight] = useState(calculateHeight(window.innerHeight));
  useEffect(adjustHeight(height, setHeight, calculateHeight));

  // Load state and note
  const [savingState, setSavingState] = useState<SavingState>('Loading');
  const [editorState, setEditorState] = useLocalStorage<EditorState>(EDITOR_STATE_KEY, EMPTY_EDITOR_STATE);
  const noteId = propsNoteId || editorState?.noteId;
  const [content, setContent] = useState('');
  const contentRef = useRef(content);

  const {
    data: note,
  } = useGetNoteQuery(noteId || '', { skip: !noteId });

  useEffect(() => {
    if (savingState === 'Loading' && (((noteId || editorState?.noteId) && note) || (!noteId && !editorState?.noteId))) {
      setSavingState('Loaded');
    }
  }, [note]);

  useEffect(() => {
    if (savingState === 'Loaded') {
      const [loadedContent, loadedEditorState] = loadNoteAndState(savingState, editorState, note);
      setContent(loadedContent);
      contentRef.current = loadedContent;
      setEditorState(loadedEditorState);
      setSavingState('Unsaved');
    }
  }, [savingState]);

  // Auto-save draft
  const isChanged = useCallback(() => () => (contentRef.current !== (note?.content || '')), [note]);
  const onChange = (newValue: string) => {
    setContent(newValue);
    contentRef.current = newValue;
  };
  const doSaveEditorStateDelayed = useCallback(_.debounce(() => {
    if (editorState && isChanged() && content !== editorState.draft) {
      setEditorState({ ...editorState, draft: content });
    }
  }, 2000), [content, editorState]);
  useEffect(() => {
    doSaveEditorStateDelayed();
    return doSaveEditorStateDelayed.cancel;
  }, [content]);

  // Editor component
  const onEditorSave = useCallback(() => {
    if (content !== (note?.content || '')) {
      setSavingState('SaveStarted');
      (noteId ? saveNote({ id: noteId, content, digest: note?.meta.digest || '' }) : createNote({ content }))
        .unwrap()
        .then((response) => dispatch(closeEditOpenView(response.id)))
        .then(() => clearEditorState())
        .catch(() => setSavingState('SaveFailed'));
    } else {
      dispatch(closeEdit());
    }
  }, [noteId, note, content]);

  const onEditorClose = useCallback(() => {
    if (content !== (note?.content || '')) {
      setSavingState('CloseStarted');
    } else {
      dispatch(closeEdit());
      clearEditorState();
    }
  }, [content, note]);

  const editorRef = useCallback((editorComponent: AceEditor) =>
    createEditor(editorComponent, onEditorSave, onEditorClose), [onEditorSave, onEditorClose]);

  const doCloseWithoutSave = () => {
    dispatch(closeEdit());
    clearEditorState();
  };

  const message = useCallback(() => {
    if (isChanged()) {
      if (savingState === 'SaveStarted') {
        return 'Saving...';
      }
      if (content === editorState?.draft) {
        return 'Draft saved';
      }

      return 'Unsaved changes';
    }

    return '';
  }, [savingState, isChanged, content, editorState]);

  const { data: mentionCompletions, isSuccess: mentionCompletionsIsSuccess } = useGetMentionCompletionsQuery();
  useEffect(() => {
    setCompleters([]);
  }, []);
  useEffect(() => {
    if (mentionCompletionsIsSuccess) {
      const mentionCompleter: Ace.Completer = {
        getCompletions(editor: Ace.Editor, session: Ace.EditSession, position: Ace.Point,
                       prefix: string, callback: Ace.CompleterCallback) {
          callback(null, _.cloneDeep(mentionCompletions));
        },
        triggerCharacters: ['@'],
      };
      setCompleters([mentionCompleter]);
    }
  }, [mentionCompletions]);

  return (
    <div className="edit note">
      <div className="header">
        <div className="title">
          Note
        </div>
      </div>
      <div className="body">
        <div className="ui form">
          <AceEditor
            ref={editorRef}
            height={`${height}px`}
            width="100%"
            className="editor"
            placeholder="Type your note in Markdown..."
            mode="markdown"
            theme="tomorrow"
            name="contentEditor"
            fontSize={16}
            showPrintMargin={false}
            showGutter={false}
            highlightActiveLine={false}
            value={content}
            onChange={onChange}
            readOnly={savingState === 'SaveStarted'}
            setOptions={{
              showLineNumbers: false,
              tabSize: 2,
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
            }}
          />
        </div>
      </div>
      <div className="actions">
        <span className="edit-status">{message()}</span>
        <Button
          icon={savingState === 'SaveStarted' ? 'spinner' : 'check'}
          loading={savingState === 'SaveStarted'}
          disabled={savingState === 'SaveStarted'}
          onClick={onEditorSave}
        />
        <Modal
          onOpen={() => setSavingState('CloseStarted')}
          onClose={() => setSavingState('Unsaved')}
          open={savingState === 'CloseStarted'}
          trigger={<Button icon="close" disabled={savingState === 'SaveStarted'} />}
          header="Discard changes?"
          content="You are discarding all changes you have made to this document."
          actions={['Back', {
            key: 'delete', content: 'Discard', positive: false, className: 'red', onClick: doCloseWithoutSave,
          }]}
        />

        <Modal
          open={savingState === 'SaveFailed'}
          header="Saving failed"
          content="Your note could not be saved. We are sorry. :("
          actions={[{
            key: 'delete', content: 'OK', positive: false, className: 'red', onClick: () => setSavingState('Unsaved'),
          }]}
        />
      </div>
    </div>
  );
};

export default NoteEdit;
