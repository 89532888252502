import { EntityState } from '@reduxjs/toolkit';
import { CustomThunkResult } from '../../app/customthunkresult';

interface TaskId {
  readonly kind: 'task';
  readonly id: string;
  readonly noteId: string;
}

interface Task extends TaskId {
  readonly description: string;
  readonly complete: boolean;
  readonly due?: string;
  readonly startPos: number;
  readonly endPos: number;
}

interface MyTasks {
  today: TaskId[];
  thisWeek: TaskId[];
  theRest: TaskId[];
}

const noteIdsFromMyTasks = (response: MyTasks | undefined): string[] => {
  if (!response) {
    return [];
  }
  const noteIds = new Set<string>();
  [response.today, response.thisWeek, response.theRest].forEach((taskIds) => {
    taskIds.forEach((taskId) => noteIds.add(taskId.noteId));
  });

  return Array.from(noteIds.values());
};

interface TaskState extends EntityState<Task> {
  myTasks: CustomThunkResult<MyTasks>;
}

export type {
  TaskId, Task, TaskState, MyTasks,
};

export {
  noteIdsFromMyTasks,
};
