/**
 * @typedef {import('hast').Element} Element
 * @typedef {import('hast').Properties} Properties
 * @typedef {import('mdast').Link} Link
 * @typedef {import('../state.js').State} State
 */

import type { Element, ElementContent, Properties } from 'hast';
import type { Link } from 'mdast';
import { State } from 'mdast-util-to-hast';
import { normalizeUri } from 'micromark-util-sanitize-uri';
import { Dictionary } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Mention } from '../../feature/mention/types';

const emailAddressElement = (email: string): ElementContent => ({
  type: 'element',
  tagName: 'div',
  properties: {
    className: ['item'],
  },
  children: [
    {
      type: 'element',
      tagName: 'a',
      properties: {
        href: `mailto:${email}`,
      },
      children: [
        {
          type: 'element',
          tagName: 'button',
          properties: {
            className: ['ui', 'icon', 'button', 'mini'],
            title: `E-mail to ${email}`,
          },
          children: [
            {
              type: 'element',
              tagName: 'i',
              properties: {
                ariaHidden: true,
                className: ['envelope', 'icon'],
              },
              children: [],
            },
          ],
        }
      ],
    }
  ],
});

const tooltip = (emailAddresses: string[] | undefined): ElementContent[] => {
  if (emailAddresses === undefined || emailAddresses.length === 0) {
    return [];
  }

  return [{
    type: 'element',
    tagName: 'div',
    properties: {
      className: ['menu'],
    },
    children: emailAddresses.map(emailAddressElement),
  }];
}

const isMentionUrl = (url: string | undefined | null): boolean =>
  (url !== undefined && url !== null && url.startsWith('urn:mention:'))

const link = (mentions: Dictionary<Mention>) => (state: State, node: Link): Element  => {
    /** @type {Properties} */
    const properties: Properties = {href: normalizeUri(node.url)};

    if (node.title !== null && node.title !== undefined) {
      properties.title = node.title
    }

    let result:Element;

    if (isMentionUrl(node.url)) {
      const mentionKey = node.url.substring(12);
      const mention = mentions[mentionKey];

      result = {
        type: 'element',
        tagName: 'span',
        properties: {
          className: ['mention', 'ui', 'simple', 'compact', 'dropdown', 'item'],
          title: mention?.fullName,
        },
        children: _.concat([
          {
            type: 'text',
            value: mention?.displayName ? mention.displayName : mentionKey,
          }
        ], tooltip(mention?.emailAddress)),
      }
    } else {
      result = {
        type: 'element',
        tagName: 'a',
        properties,
        children: state.all(node)
      }

    }
    /** @type {Element} */

  state.patch(node, result)
  return state.applyData(node, result)
}

export default link;