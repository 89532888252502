import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { AuthProvider } from 'react-oidc-context';
import { createRoot } from 'react-dom/client';
import { keycloakAuthProviderProps } from './auth';
import { store } from './app/store';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import AppRouter from './router/AppRouter';
import envConfig from './env-config';

if (envConfig.NODE_ENV === 'production') {
  Sentry.init({
    dsn: envConfig.SENTRY_DSN,
    environment: envConfig.ENVIRONMENT,
    dist: envConfig.ENVIRONMENT,
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/app\.notenote.me\/api/,
      /^https:\/\/app\.staging\.notenote.me\/api/
    ],
    profilesSampleRate: 1.0,
    normalizeDepth: 10, // Or however deep you want your state context to be.
    tunnel: '/api/sy/t',
  });
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Provider store={store}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <AuthProvider {...keycloakAuthProviderProps(envConfig.KEYCLOAK_URL, envConfig.AUTH_REALM, envConfig.SELF_URL)}>
          <AppRouter />
        </AuthProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
