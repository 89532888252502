const envConfig = {
  NODE_ENV: process.env.NODE_ENV,
  SELF_URL: process.env.REACT_APP_SELF_URL,
  KEYCLOAK_URL: process.env.REACT_APP_KEYCLOAK_URL,
  AUTH_REALM: process.env.REACT_APP_AUTH_REALM,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
};

export default envConfig;
