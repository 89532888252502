import { EntityState } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Entity } from '../../app/util';

interface Feed extends Entity {
  readonly kind: 'feed';
  readonly id: string;
  query?: string;
  noteIds?: string[];
  isLoading: boolean;
}

const isFeed = (feed: Feed | undefined): feed is Feed => (feed?.kind === 'feed' &&
    _.isString(feed.id) &&
    _.isBoolean(feed.isLoading) &&
    _.isArray(feed.noteIds) &&
    _.reduce(feed.noteIds, (acc, id) => acc && _.isString(id), true) &&
    (!feed.query || _.isString(feed.query)));

const isFeedState = (feeds: FeedState | undefined): feeds is FeedState => {
  if (!feeds) {
    return false;
  }

  const { ids, entities } = feeds;
  if (!_.isArray(ids) || !_.isObject(entities)) {
    return false;
  }

  return _.reduce(ids, (acc, id) => acc && isFeed(entities[id]), true);
}

const feedRemoveNote = (feed: Feed, noteId: string) => {
  const noteIds = feed.noteIds?.filter((id) => id !== noteId);
  return {
    ...feed,
    noteIds,
  };
};

interface FeedState extends EntityState<Feed> {
  serial: number,
}

const removeNoteFromFeeds = (state: FeedState, noteId: string): FeedState => {
  const entities = _.mapValues(state.entities, (feed) => (feed ? feedRemoveNote(feed, noteId) : feed));

  return {
    ...state,
    entities,
  };
};

export type { Feed, FeedState };
export { removeNoteFromFeeds, isFeed, isFeedState };
