type AttributeConstraint = Array<RegExp | boolean | number | string> | string;

const attributeMerge = (list1: AttributeConstraint[], list2: AttributeConstraint[]): AttributeConstraint[] => {
  const result: AttributeConstraint[] = [];

  list1.forEach((item1) => {
    if (!Array.isArray(item1) && !result.includes(item1)) {
      result.push(item1);
    }

    if (Array.isArray(item1)) {
      const item2 = list2.find((i) => Array.isArray(i) && i[0] === item1[0]);
      if (Array.isArray(item2)) {
        result.push([item1[0], ...item1.slice(1), ...item2.slice(1)]);
      } else {
        result.push(item1);
      }
    }
  });

  list2.forEach((item2) => {
    if (!Array.isArray(item2) && !result.includes(item2)) {
      result.push(item2);
    }

    if (Array.isArray(item2)) {
      const item1 = list1.find((i) => Array.isArray(i) && i[0] === item2[0]);
      if (!Array.isArray(item1)) {
        result.push(item2);
      }
    }
  });

  return result;
}

const applyCustomMerge = (keys: string[]) => (key: string) => {
  if (keys.includes(key)) {
    return attributeMerge;
  }
  return undefined;
}

export type { AttributeConstraint };
export { attributeMerge, applyCustomMerge };
