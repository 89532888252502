import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { addAuthorization } from '../../auth';
import { Contact, ContactCreate, ContactUpdate } from './types';
import { mentionApi } from '../mention/mentionApi';

type ApiError = {
  error: {
    readonly status: number;
  };
};

export const contactApi = createApi({
  reducerPath: 'contactApi',
  tagTypes: ['contact'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: addAuthorization,
  }),
  endpoints: (build) => ({
    getContact: build.query<Contact, string>({
      query: (id) => `/contact/${id}`,
      providesTags: (result) => [{ type: 'contact' as const, id: result?.id }],
    }),
    saveContact: build.mutation<Contact, ContactUpdate>({
      query: (contactUpdate) => ({
        url: `/contact/${contactUpdate.contact.id}`,
        method: 'PUT',
        body: contactUpdate.contact,
        headers: [['If-Match', `"${contactUpdate.digest}"`]],
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedContact } = await queryFulfilled;
          dispatch(contactApi.util.upsertQueryData('getContact', updatedContact.id, updatedContact));
          dispatch(mentionApi.util.invalidateTags([{ type: 'mention' as const, id: updatedContact.mentionKey }]));
        } catch (ex) {
          const { error } = ex as ApiError;
          if (error.status === 412) {
            dispatch(contactApi.util.invalidateTags([{ type: 'contact', id: queryArg.contact.id }]));
          }
        }
      },
    }),
    createContact: build.query<Contact, ContactCreate>({
      query: (contact: ContactCreate) => ({
        url: '/contact/',
        method: 'POST',
        body: contact,
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(contactApi.util.invalidateTags(['contact']));
        dispatch(mentionApi.util.invalidateTags([{ type: 'mention' as const, id: queryArg.mentionKey }]));
      }
    }),
    deleteContact: build.mutation<void, Contact>({
      query: (contact) => ({
        url: `/contact/${contact.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, contact) => [{ type: 'contact' as const, id: contact.id }],
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(mentionApi.util.invalidateTags([{ type: 'mention' as const, id: queryArg.mentionKey }]));
      }
    }),
    getAllContacts: build.query<Contact[], void>({
      query: () => ({
        url: '/contact/',
        method: 'GET',
      }),
      providesTags: (result) =>
        result ?
          [...result.map(({ id }) => ({ type: 'contact' as const, id })), 'contact'] :
          ['contact']
    }),
    getManyContacts: build.query<Contact[], string[]>({
      query: (contactIds: string[]) => ({
        url: '/contact/',
        method: 'GET',
        params: {
          'id[]': contactIds,
        },
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          const { data: contacts } = await queryFulfilled;
          contacts.forEach((contact) => {
            dispatch(contactApi.util.upsertQueryData('getContact', contact.id, contact));
          });
        } catch {
          // Nothing to do
        }
      },
    }),
    searchContacts: build.query<Contact[], string>({
      query: (query) => ({
        url: '/contact/search/',
        params: {
          query,
        },
        method: 'GET',
      }),
      providesTags: (result) =>
        result ?
          [...result.map(({ id }) => ({ type: 'contact' as const, id })), 'contact'] :
          ['contact'],
    }),
  }),
});

export const {
  useGetContactQuery,
  useLazyGetContactQuery,
  useSaveContactMutation,
  useLazyCreateContactQuery,
  useDeleteContactMutation,
  useGetAllContactsQuery,
  useLazyGetAllContactsQuery,
  useGetManyContactsQuery,
  useLazyGetManyContactsQuery,
  useLazySearchContactsQuery,
} = contactApi;
