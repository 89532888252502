import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Draft,
  EntityAdapter,
  EntityId,
  PayloadAction,
} from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { MyTasks, noteIdsFromMyTasks, Task, TaskState } from './types';
import { Note } from '../note/types';
import { noteApi } from '../note/noteApi';
import { taskApi } from './taskApi';
import {
  CustomThunkResult,
  fetchingCustomThunkResult,
  rejectedCustomThunkResult,
  successCustomThunkResult,
  uninitializedCustomThunkResult,
} from '../../app/customthunkresult';

const taskAdapter: EntityAdapter<Task> = createEntityAdapter();

const initialState: TaskState = {
  ids: [],
  entities: {},
  myTasks: uninitializedCustomThunkResult<MyTasks>(),
};

const selectTaskIds = (state: Draft<TaskState>, filterFn: (t: Task) => boolean): EntityId[] =>
  taskAdapter
    .getSelectors()
    .selectAll(state)
    .filter(filterFn)
    .map((t) => t.id);

const updateTasksFromNote = (state: Draft<TaskState>, action: PayloadAction<Note>): void => {
  taskAdapter.removeMany(
    state,
    selectTaskIds(state, (t) => t.noteId === action.payload.id)
  );
  taskAdapter.upsertMany(state, action.payload.tasks);
};

export const getMyTasksWithNotes = createAsyncThunk('task/getMyTasksWithNotes', async (_never, { dispatch }) => {
  const { data: myTasks } = await dispatch(taskApi.endpoints.getMyTasks.initiate());
  await dispatch(noteApi.endpoints.getManyNotes.initiate(noteIdsFromMyTasks(myTasks)));

  return successCustomThunkResult(myTasks);
});

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getMyTasksWithNotes.pending, (state) => ({
        ...state,
        myTasks: fetchingCustomThunkResult(state.myTasks),
      }))
      .addCase(getMyTasksWithNotes.rejected, (state) => ({ ...state, myTasks: rejectedCustomThunkResult() }))
      .addCase(getMyTasksWithNotes.fulfilled, (state, action) => ({ ...state, myTasks: action.payload }))
      .addMatcher(taskApi.endpoints.getMyTasks.matchFulfilled, (state, action) => ({
        ...state,
        myTasks: successCustomThunkResult(action.payload),
      }))
      .addMatcher(noteApi.endpoints.getNote.matchFulfilled, updateTasksFromNote)
      .addMatcher(noteApi.endpoints.saveNote.matchFulfilled, updateTasksFromNote)
      .addMatcher(noteApi.endpoints.createNote.matchFulfilled, updateTasksFromNote)
      .addMatcher(noteApi.endpoints.deleteNote.matchFulfilled, (state, action) => {
        taskAdapter.removeMany(
          state,
          selectTaskIds(state, (t) => t.noteId === action.meta.arg.originalArgs)
        );
      }),
});

export const selectMyTasks = (state: RootState): CustomThunkResult<MyTasks> => state.task.myTasks;
export default taskSlice.reducer;
