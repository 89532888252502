import { Dropdown, Menu } from 'semantic-ui-react';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useAppDispatch } from '../../app/hooks';
import { setContactsOpen } from './appSlice';

const PersonalMenu = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const { user } = auth;

  const name = user?.profile?.name || 'Personal menu';
  const accountUrl = `${user?.profile.iss || ''}/account/`;

  if (!auth.isAuthenticated) {
    return null;
  }

  return (
    <Menu inverted className="right">
      <Dropdown text={name} simple item>
        <Dropdown.Menu>
          <Dropdown.Item icon="user" text="Account" href={accountUrl} />
          <Dropdown.Divider />
          <Dropdown.Item icon="address book" text="Contacts" onClick={() => dispatch(setContactsOpen(true))} />
          <Dropdown.Divider />
          <Dropdown.Item icon="sign out" text="Log out" onClick={() => auth.signoutRedirect()} />
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
};

export default PersonalMenu;
