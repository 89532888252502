import _ from 'lodash';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

interface RestError {
  readonly status: number;
  readonly error: string;
  readonly message: string;
  readonly timestamp: string;
  readonly path: string;
}

interface FieldValidationError {
  readonly field: string;
  readonly code: string;
  readonly message: string;
}

const isFieldValidationError = (e: unknown): e is FieldValidationError => _.isObject(e)
  && 'field' in e && _.isString(e.field)
  && 'code' in e && _.isString(e.code)
  && 'message' in e && _.isString(e.message)

interface ValidationError extends RestError {
  readonly errors: FieldValidationError[];
}

const isValidationError = (error: unknown): error is ValidationError => _.isObject(error)
  && 'status' in error && _.isNumber(error.status)
  && 'error' in error && _.isString(error.error)
  && 'message' in error && _.isString(error.message)
  && 'timestamp' in error && _.isString(error.timestamp)
  && 'path' in error && _.isString(error.path)
  && 'errors' in error && Array.isArray(error.errors) && _.every(error.errors, isFieldValidationError)

interface FieldError {
  readonly field: string;
  readonly content: string;
  readonly pointing?: string;
}

const toFieldError = (e: FieldValidationError, pointing?: string): FieldError => ({
  field: e.field,
  content: _.capitalize(e.message),
  pointing,
});

interface FormError {
  [field: string]: FieldError;
}

const toFormError = (e: ValidationError | undefined, pointing?: string): FormError =>
  _.chain(e?.errors || {})
    .map(er => toFieldError(er, pointing))
    .keyBy('field')
    .value();

const nativeError = (e : FetchBaseQueryError | SerializedError | undefined): ValidationError | undefined => {
  if (typeof e === 'object' && 'data' in e && typeof e.data === 'object' && isValidationError(e.data)) {
    return e.data;
  }

  return undefined;
}

export type { RestError, ValidationError };
export { isValidationError, toFormError, nativeError };
