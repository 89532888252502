import { Button, Form, Header, InputOnChangeData, Segment, Table } from 'semantic-ui-react';
import './ContactRow.css';
import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useSaveContactMutation } from './contactApi';
import { Contact } from './types';
import { closeEdit } from './contactSlice';
import { nativeError, toFormError } from '../../app/rest-error';
import { useAppDispatch } from '../../app/hooks';

type EditedContact = Omit<Contact, 'kind' | 'id' | 'mentionKey' | 'meta'>;
const defaultFormValues = { displayName: '', emailAddress: '' };

type ContactEditorProps = {
  contact: Contact;
};
const ContactCard = (props: ContactEditorProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const [saveContact, { error, isLoading: isSaving }] = useSaveContactMutation();
  const [formValue, setFormValue] = useState(defaultFormValues as EditedContact);

  const { contact } = props;

  useEffect(() => {
    setFormValue(_.defaults(_.omit(contact, ['kind', 'id', 'mentionKey', 'meta']), defaultFormValues));
  }, [contact]);

  const onSubmit = useCallback(() => {
    saveContact({ contact: _.assign(_.omit(contact, 'meta'), formValue), digest: contact?.meta.digest || '' })
      .unwrap().then(() => dispatch(closeEdit()));
  }, [contact, formValue]);

  const onChange = (_e: ChangeEvent, inputValue: InputOnChangeData) => {
    const { id, value } = inputValue;
    setFormValue({ ...formValue, [id]: value || '' });
  }

  const { mentionKey } = contact || {};
  const formError = toFormError(nativeError(error));
  return (
    <Table.Row className="ContactRow">
      <Table.Cell colSpan="5">
        <Segment>
          <Header>{mentionKey}</Header>
          <Form onSubmit={onSubmit} value={formValue}>
            <Form.Group>
              <Form.Input
                id="fullName"
                label="Name"
                placeholder="eg. Allan Smith"
                value={formValue.fullName}
                onChange={onChange}
                readOnly={isSaving}
                error={formError.displayName}
                width="3"
              />
              <Form.Input
                id="displayName"
                label="Display name"
                placeholder="Display name"
                value={formValue.displayName}
                onChange={onChange}
                readOnly={isSaving}
                error={formError.displayName}
                width="3"
              />
              <Form.Input
                id="emailAddress"
                label="E-mail address"
                placeholder="E-mail address"
                value={formValue.emailAddress}
                onChange={onChange}
                readOnly={isSaving}
                error={formError.emailAddress}
                width="7"
              />
            </Form.Group>

            <Button icon={isSaving ? 'spinner' : 'check'} disabled={isSaving} type="submit" />
            <Button icon="close" disabled={isSaving} onClick={() => dispatch(closeEdit())} />
          </Form>
        </Segment>
      </Table.Cell>
    </Table.Row>
  );
}

export default ContactCard;
