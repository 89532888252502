import * as React from 'react';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Grid, Loader } from 'semantic-ui-react';
import { useLazyValidateIdentityQuery } from '../feature/user/identityapi';
import App from '../feature/app/App';
import { adjustHeight } from '../app/util';

const calculateHeight = (windowHeight: number) => windowHeight - 20;

const AppRouter = (): JSX.Element | null => {
  const auth = useAuth();
  const [validateIdentity, result] = useLazyValidateIdentityQuery();
  const { isAuthenticated, isLoading, activeNavigator, signinRedirect } = auth;

  const [height, setHeight] = useState(calculateHeight(window.innerHeight));
  useEffect(adjustHeight(height, setHeight, calculateHeight));
  
  // Automatic login
  useEffect(() => {
    if (!hasAuthParams() && !isAuthenticated && !activeNavigator && !isLoading) {
      auth.signinRedirect();
    }
  }, [isAuthenticated, activeNavigator, isLoading, signinRedirect]);

  // Redirect to log in if session becomes invalid
  useEffect(() => {
    const handleSilentRenewError = () => auth.signinRedirect();
    auth.events.addSilentRenewError(handleSilentRenewError);
    return () => auth.events.removeSilentRenewError(handleSilentRenewError);
  }, [auth.events, signinRedirect]);

  // Run init upon log in
  useEffect(() => {
    if (isAuthenticated) {
      validateIdentity();
    }
  }, [isAuthenticated]);

  // Loading screen
  if (isLoading || !result.isSuccess) {
    return (
      <Grid verticalAlign="middle" centered>
        <Grid.Column>
          <Loader inverted active>
            Loading
          </Loader>
          <div style={{ height }} />
        </Grid.Column>
      </Grid>
    );
  }

  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppRouter;
