import { findAndReplace } from "mdast-util-find-and-replace";
import { PhrasingContent } from 'remark-mentions/lib';
import { MdastRoot, VFile } from 'remark-rehype/lib';

const userGroup = "[\\da-z][_-\\da-z_]{0,63}";
const mentionRegex = new RegExp(
  `(?:^|\\s)@(${  userGroup  })`,
  "gi"
);

const defaultUsernameLinkFn = (username: string) => (`urn:mention:@${username}`);

export default function remarkMentions(opts = { usernameLink: defaultUsernameLinkFn }) {

  function replaceMention(value: string, username: string): PhrasingContent[] {
    /** @type {PhrasingContent[]} */
    const whitespace: PhrasingContent[] = [];

    // Separate leading white spacespan
    if (value.indexOf("@") > 0) {
      whitespace.push({
        type: "text",
        value: value.substring(0, value.indexOf("@")),
      });
    }

    return [
      ...whitespace,
      {
        type: "link",
        url: opts.usernameLink(username),
        data: {
          linkType: 'mention',
        },
        children: [
          { type: "text", value: value.trim() }, // Trim the username here
        ],
      },
    ];
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  return (tree: MdastRoot, _file: VFile) => {
    findAndReplace(tree, [[mentionRegex, replaceMention]]);
  };
}

