import { EntityId } from '@reduxjs/toolkit';
import _, { debounce } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const when = <T, R>(obj: T | undefined | null, ifDefined: (t: T) => R, ifUndefined: () => R): R => {
  if (obj !== null && obj !== undefined) {
    return ifDefined(obj);
  }

  return ifUndefined();
};

const adjustHeight = (
  height: number,
  setHeight: (h: number) => void,
  calculateHeight: (h: number) => number,
) => (): () => void => {
  const debouncedHandleResize = debounce(() => {
    setHeight(calculateHeight(window.innerHeight));
  }, 400);

  window.addEventListener('resize', debouncedHandleResize);

  return () => {
    window.removeEventListener('resize', debouncedHandleResize);
  };
};

const getTimeZone = (): string => dayjs.tz.guess();

const formatUtcDate = (date: string | undefined): string => {
  if (!date) {
    return '';
  }

  const format = 'D MMMM YYYY HH:mm';
  return dayjs.utc(date).local().format(format);
};

const formatLocalDate = (date: string | undefined): string => {
  if (!date) {
    return '';
  }

  const current = dayjs();
  const d = dayjs(date);

  let format = 'MMMM D';
  if (d.year() !== current.year()) {
    format += ' YYYY';
  }
  if (d.hour() !== 23 || d.minute() !== 59) {
    format += ' HH:mm';
  }

  return d.format(format);
};

const getTimeZoneOffset = (): string => {
  const offsetMinutes = dayjs().utcOffset();

  const hours = _.floor(_.divide(Math.abs(offsetMinutes), 60));
  const minutes = Math.abs(offsetMinutes) - (hours * 60);
  const prefix = ((offsetMinutes >= 0) ? '+' : '-');

  return `${prefix}${dayjs().hour(hours).minute(minutes).format('HH:mm')}`;
};

const storageAvailable = (type: string): boolean => {
  let storage;
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    storage = (window as any)[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && (
      e.name === 'SecurityError'
        || e.name === 'QuotaExceededError'
        || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
      && (storage && storage.length !== 0);
  }
};

const localStorageAvailable = storageAvailable('localStorage');

interface Entity {
  readonly id: EntityId;
  readonly kind: string;
}

type AsyncStatus = 'fulfilled' | 'pending' | 'rejected' | 'absent';

export type { AsyncStatus, Entity };
export {
  when,
  adjustHeight,
  formatUtcDate,
  formatLocalDate,
  getTimeZoneOffset,
  getTimeZone,
  storageAvailable,
  localStorageAvailable,
};

