import { useEffect, useState } from 'react';
import FeedSearchBar from './FeedSearchBar';
import NoteCard from '../note/NoteCard';
import type { Feed } from './types';
import './Feed.css';
import { adjustHeight } from '../../app/util';

interface FeedProps {
  feed: Feed;
}

const calculateHeight = (windowHeight: number) => windowHeight - 168;

const FeedColumn = (props: FeedProps): JSX.Element => {
  const { feed } = props;

  const [height, setHeight] = useState(calculateHeight(window.innerHeight));
  useEffect(adjustHeight(height, setHeight, calculateHeight));

  const notePreviews = feed
    ? feed.noteIds?.map((id) => (<NoteCard key={id} noteId={id} />)) : null;

  return (
    <div className="feed preview">
      <FeedSearchBar feed={feed} />
      <div className="body" style={{ height }}>
        {notePreviews}
      </div>
    </div>
  );
};

export default FeedColumn;
