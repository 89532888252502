import { User } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';
import envConfig from './env-config';

const onSigninCallback = (): void => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname,
  );
};

const keycloakAuthProviderProps = (keycloakUrl?: string, realm?: string, selfUri?: string): AuthProviderProps => ({
  authority: `${keycloakUrl}/realms/${realm}`,
  redirect_uri: selfUri || '',
  post_logout_redirect_uri: selfUri || '',
  client_id: 'notenote-frontend',
  loadUserInfo: true,
  onSigninCallback,
});

const userKey = `oidc.user:${envConfig.KEYCLOAK_URL}/realms/${envConfig.AUTH_REALM}:notenote-frontend`;
function getUser() {
  const oidcStorage = sessionStorage.getItem(userKey);

  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

const addAuthorization = (headers: Headers): Headers => {
  const user = getUser();
  headers.set('Authorization', `Bearer ${user?.access_token}`);
  return headers;
};

export { keycloakAuthProviderProps, addAuthorization };
