import { useAppSelector } from '../../app/hooks';
import { selectApp } from './appSlice';
import TopMenu from './TopMenu';
import Feeds from '../feeds/Feeds';
import NoteView from '../note/NoteView';
import NoteEdit from '../note/NoteEdit';
import './App.css';
import { clearEditorState } from '../note/editor';
import ContactsModal from '../contact/ContactsModal';

const App = (): JSX.Element => {
  const {
    view,
    edit,
    contacts,
  } = useAppSelector(selectApp);

  const components = [];
  const classNames = ['app'];
  if (view.noteId) {
    components.push(<NoteView key="view" noteId={view.noteId} />);
    classNames.push('view');
  }
  if (edit.active) {
    components.push(<NoteEdit key="edit" noteId={edit.noteId} />);
    classNames.push('edit');
  } else {
    clearEditorState();
  }

  return (
    <>
      <div className={classNames.join(' ')}>
        <TopMenu key="topMenu" />
        <Feeds key="feeds" />
        {components}
      </div>
      {contacts.open && (<ContactsModal open={contacts.open} />)}
    </>
  );
};

export default App;
