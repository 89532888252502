import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { addAuthorization } from '../../auth';

type FeedQuery = {
  searchQuery: string;
  feedId: string;
};

export const feedApi = createApi({
  reducerPath: 'feedApi',
  tagTypes: ['feed'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: addAuthorization,
  }),
  endpoints: (build) => ({
    search: build.query<string[], FeedQuery>({
      query: ({ searchQuery }) => ({
        url: '/note/search',
        params: {
          query: searchQuery,
        },
        method: 'GET',
      }),
      providesTags: () => ([{ type: 'feed' as const, id: 'SEARCH' }]),
    }),
  }),
});

export type { FeedQuery };
export const { useSearchQuery, useLazySearchQuery } = feedApi;
