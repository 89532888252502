import {
  Button, Form, Icon, Input,
} from 'semantic-ui-react';
import {
  ChangeEvent, FormEvent, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import _ from 'lodash';
import { useAppDispatch } from '../../app/hooks';
import { Feed } from './types';
import { close } from './feedsSlice';
import './FeedSearchBar.css';
import { useLazySearchQuery } from './feedApi';

type FeedSearchBarProps = {
  feed: Feed;
};

const FeedSearchBar = (props: FeedSearchBarProps): JSX.Element => {
  const { feed } = props;
  const { query: defaultQuery, id, isLoading } = feed;

  const [searchNotes] = useLazySearchQuery();
  const dispatch = useAppDispatch();

  const [query, setQuery] = useState(_.isString(defaultQuery) ? defaultQuery : '');

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    searchNotes({ searchQuery: query, feedId: id });
  };

  useEffect(() => {
    if (query.length > 0) {
      searchNotes({ searchQuery: query, feedId: id });
    }
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="header">
      <Form onSubmit={onSubmit}>
        <Input placeholder="Keywords..." value={query} onChange={onChange} loading={isLoading} icon iconPosition="left">
          <Icon name="search" />
          <input ref={inputRef} />
        </Input>
      </Form>
      <div className="actions">
        <Button icon="close" onClick={() => dispatch(close(feed))} />
      </div>
    </div>
  );
};

export default FeedSearchBar;
