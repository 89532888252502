import { Button, Modal } from 'semantic-ui-react';
import { createElement, Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { closeView, openEdit } from '../app/appSlice';
import './Note.css';
import './NoteView.css';
import { useDeleteNoteMutation } from './noteApi';
import { adjustHeight, formatUtcDate } from '../../app/util';
import { LOAD_TASKS, onTaskChange } from '../task/task';
import { selectNoteById } from './noteSlice';
import markdownToJsx from '../../app/remark';
import { useGetMentionsQuery } from '../mention/mentionApi';

type NoteViewProps = {
  noteId: string;
};

const calculateHeight = (windowHeight: number) => windowHeight - 168;

const NoteView = (props: NoteViewProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { noteId } = props;
  const note = useAppSelector((state) => selectNoteById(state, noteId));
  const { data: mentionsList } = useGetMentionsQuery(note?.mentions || [], { skip: !note || _.isEmpty(note.mentions) });

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [height, setHeight] = useState(calculateHeight(window.innerHeight));
  useEffect(adjustHeight(height, setHeight, calculateHeight));
  const [deleteNote] = useDeleteNoteMutation();

  const [content, setContent] = useState<JSX.Element>(createElement(Fragment));

  useEffect(() => {
    (async function renderContent() {
      if (note && (_.isEmpty(note.mentions) || mentionsList)) {
        const fragment = await markdownToJsx(note, _.keyBy(mentionsList, 'id'),'View');
        setContent(fragment);
      }
    })();
  }, [note?.content, mentionsList]);

  if (!note) {
    return null;
  }

  const { created } = note.meta;

  const onDeleteConfirmed = () => {
    deleteNote(note.id);
  };

  return (
    <div className="note view">
      <div style={{ display: 'none' }}>
        {LOAD_TASKS}
      </div>

      <div className="header">
        <div className="date">
          {formatUtcDate(created)}
        </div>
        <div className="actions">
          <Button icon="edit" onClick={() => dispatch(openEdit(noteId))} />
          <Modal
            onOpen={() => setDeleteConfirmOpen(true)}
            onClose={() => setDeleteConfirmOpen(false)}
            open={deleteConfirmOpen}
            trigger={<Button icon="trash" />}
            header="Delete note?"
            content="You are deleting this note permanently."
            actions={['Back', {
              key: 'enter', content: 'Delete', positive: false, className: 'red', onClick: onDeleteConfirmed,
            }]}
          />
          <Button icon="close" onClick={() => dispatch(closeView())} />
        </div>
      </div>

      <div className="body" style={{ height }} onChange={onTaskChange}>{content}</div>
      
    </div>
  );
};

export default NoteView;
