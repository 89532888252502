import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { MyTasks } from './types';
import { getTimeZoneOffset } from '../../app/util';
import { addAuthorization } from '../../auth';

export const taskApi = createApi({
  reducerPath: 'taskApi',
  tagTypes: ['task'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: addAuthorization,
  }),
  endpoints: (build) => ({
    getMyTasks: build.query<MyTasks, void>({
      query: () => ({
        url: '/task/myTasks',
        method: 'GET',
        params: {
          tz: getTimeZoneOffset(),
        },
      }),
      providesTags: () => ([{ type: 'task' as const, id: 'MYTASKS' }]),
    }),
  }),
});

export const {
  useGetMyTasksQuery,
  useLazyGetMyTasksQuery,
} = taskApi;
