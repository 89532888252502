import { Button, Form, Header, InputOnChangeData, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import './ContactRow.css';
import { ChangeEvent, useState } from 'react';
import { useLazyCreateContactQuery } from './contactApi';
import { useAppDispatch } from '../../app/hooks';
import { closeCreate } from './contactSlice';
import { nativeError, toFormError } from '../../app/rest-error';
import { ContactCreate } from './types';

const ContactCreateForm = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const [createContact, { error, isLoading }] = useLazyCreateContactQuery();
  const [formValue, setFormValue] = useState({
  });

  const onChange = (_e: ChangeEvent, inputValue: InputOnChangeData) => {
    const { id, value } = inputValue;
    if (value) {
      setFormValue({ ...formValue, [id]: value });
    } else {
      setFormValue(_.omit(formValue, [id]));
    }
  }

  const onSubmit = () => {
    createContact(formValue as ContactCreate).unwrap().then(() => dispatch(closeCreate()));
  }

  const formError = toFormError(nativeError(error));

  return (
    <Segment>
      <Header>Create contact</Header>
      <Form onSubmit={onSubmit}>
        <Form.Input id="mentionKey" label="Mention" placeholder="@mention"
                    required onChange={onChange} readOnly={isLoading} error={formError.mentionKey}/>
        <Form.Input id="fullName" label="Name" placeholder="eg. Allan Smith"
                    onChange={onChange} readOnly={isLoading} error={formError.displayName}/>
        <Form.Input id="displayName" label="Display name (overrides mention when displayed)" placeholder="Display name"
                    onChange={onChange} readOnly={isLoading} error={formError.displayName}/>
        <Form.Input id="emailAddress" label="Email" placeholder="Email"
                    onChange={onChange} readOnly={isLoading} error={formError.emailAddress}/>

        <Button icon={isLoading ? 'spinner' : 'check'} disabled={isLoading} type="submit" />
        <Button icon="close" disabled={isLoading} onClick={() => dispatch(closeCreate())} />
      </Form>
    </Segment>
  )
}

export default ContactCreateForm;
