import { Button, Table } from 'semantic-ui-react';
import './ContactRow.css';
import { useAppDispatch } from '../../app/hooks';
import { confirmDelete, openEdit } from './contactSlice';
import { Contact } from './types';

type ContactRowProps = {
  contact: Contact;
};

const ContactRow = (props: ContactRowProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { contact } = props;
  if (!contact) {
    return null;
  }

  const fullName = contact.fullName || '';
  const displayName = contact.displayName || '';
  const emailAddress = contact.emailAddress || '';

  return (
    <Table.Row className="ContactRow">
      <Table.Cell>{contact.mentionKey}</Table.Cell>
      <Table.Cell>{fullName}</Table.Cell>
      <Table.Cell>{displayName}</Table.Cell>
      <Table.Cell>{emailAddress}</Table.Cell>
      <Table.Cell className="actions">
        <Button icon="edit" onClick={() => dispatch(openEdit(contact.id))}  />
        <Button icon="trash" onClick={() => dispatch(confirmDelete(contact))}  />
      </Table.Cell>
    </Table.Row>
  );
}

export default ContactRow;
