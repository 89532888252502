import AceEditor from 'react-ace';
import { Note } from './types';

const calculateHeight = (windowHeight: number) => windowHeight - 218;

type SavingState = 'Loading' | 'Loaded' | 'Unsaved' | 'SaveStarted' | 'SaveFailed' | 'Saved' | 'CloseStarted';

type EditorState = {
  readonly noteId: string | undefined;
  readonly digest: string | undefined;
  draft: string;
};

const EMPTY_EDITOR_STATE: EditorState = { noteId: undefined, digest: undefined, draft: '' };

const createEditor = (editorComponent: AceEditor | undefined, onEditorSave: () => void, onEditorClose: () => void) => {
  if (editorComponent) {
    const { editor } = editorComponent;

    editor.commands.addCommand({
      name: 'close',
      bindKey: 'esc',
      exec() {
        onEditorClose();
      },
    });
    editor.commands.addCommand({
      name: 'save',
      bindKey: 'ctrl-enter',
      exec() {
        onEditorSave();
      },
    });
    editor.commands.bindKey('Tab', {
      name: 'blockindent',
      bindKey: { mac: 'Tab', win: 'Tab' },
      exec: (e) => {
        e.blockIndent();
      },
    });

    editor.focus();
  }
};

const loadNoteAndState = (
  state: SavingState,
  editorState: EditorState | undefined,
  note: Note | undefined,
): [content: string, editorState: EditorState] => {
  if (editorState?.draft) {
    return [editorState?.draft, editorState];
  }

  if (note) {
    return [note.content, { noteId: note.id, digest: note.meta.digest, draft: note.content }];
  }
  return ['', EMPTY_EDITOR_STATE];
};

const EDITOR_STATE_KEY = 'note/NoteEdit/editorState';
const clearEditorState = () => {
  localStorage.removeItem(EDITOR_STATE_KEY);
};

const hasSavedEditorState = (): boolean => (localStorage.getItem(EDITOR_STATE_KEY) !== null);

export type { SavingState, EditorState };
export {
  calculateHeight,
  createEditor,
  loadNoteAndState,
  EMPTY_EDITOR_STATE,
  EDITOR_STATE_KEY,
  clearEditorState,
  hasSavedEditorState,
};
