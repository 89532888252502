import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';

import { useAppDispatch } from '../../app/hooks';
import { openDraft } from './appSlice';
import Recent from './Recent';
import PersonalMenu from './PersonalMenu';
import QuickSearch from '../quicksearch/QuickSearch';
import MyTasksMenu from '../task/MyTasksMenu';

const TopMenu = (): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <Menu inverted className="top-menu">
      <div className="item">
        <img src="nn-icon-48x48.png" alt="Notenote" />
        &nbsp;&nbsp;Notenote
      </div>
      <Menu.Item name="new_note" onClick={() => dispatch(openDraft({ id: undefined }))}>
        <Icon name="pencil" />
        New note
      </Menu.Item>
      <Recent />
      <MyTasksMenu />
      <div className="item">
        <QuickSearch />
      </div>
      <PersonalMenu />
    </Menu>
  );
};

export default TopMenu;
