import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppState from './types';
import type { RootState } from '../../app/store';
import { noteApi } from '../note/noteApi';
import { hasSavedEditorState } from '../note/editor';

const initialState: AppState = {
  view: {
    noteId: undefined,
  },
  edit: {
    active: hasSavedEditorState(),
    noteId: undefined,
  },
  recents: [],
  contacts: {
    open: false,
  }
};

const isViewed = (noteId: string | undefined, state: AppState) => {
  const { view } = state;
  return (view.noteId && noteId === view.noteId);
};

const isEdited = (noteId: string, state: AppState) => {
  const { edit } = state;
  return (edit.active && noteId === edit.noteId);
};

const addRecent = (recents: string[], noteId: string) => {
  const index = recents.indexOf(noteId);
  if (index < 0) {
    return [noteId, ...recents].slice(0, 20);
  }
  if (index >= 1) {
    return [noteId, ...recents.filter((id) => id !== noteId)].slice(0, 20);
  }

  return recents;
};

const doOpenView = (state: AppState, noteId: string): AppState => ({
  ...state,
  view: {
    noteId: isEdited(noteId, state) ? undefined : noteId,
  },
  recents: addRecent(state.recents, noteId),
});

const doCloseEditOpenView = (state: AppState, noteId: string): AppState => ({
  ...state,
  view: {
    noteId,
  },
  edit: { active: false, noteId: undefined },
  recents: addRecent(state.recents, noteId),
});

const doOpenEdit = (state: AppState, noteId: string): AppState => ({
  ...state,
  view: {
    noteId: isViewed(noteId, state) ? undefined : state.view.noteId,
  },
  edit: {
    active: true,
    noteId,
  },
  recents: addRecent(state.recents, noteId),
});

const doOpenDraft = (state: AppState, draft: { id: string | undefined }): AppState => ({
  ...state,
  edit: {
    active: true,
    noteId: draft.id,
  },
});

const doCloseView = (state: AppState) => ({
  ...state,
  view: {
    noteId: undefined,
  },
});

const doCloseEdit = (state: AppState) => ({
  ...state,
  edit: {
    active: false,
    noteId: undefined,
  },
});

const doSetContactsOpen = (state: AppState, open: boolean) => ({
  ...state,
  contacts: {
    open,
  },
});

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openView: (state, action: PayloadAction<string>) => (doOpenView(state, action.payload)),
    closeEditOpenView: (state, action: PayloadAction<string>) => (doCloseEditOpenView(state, action.payload)),
    openEdit: (state, action: PayloadAction<string>) => (doOpenEdit(state, action.payload)),
    openDraft: (state, action: PayloadAction<{ id: string | undefined }>) => (doOpenDraft(state, action.payload)),
    closeView: (state) => (doCloseView(state)),
    closeEdit: (state) => (doCloseEdit(state)),
    setContactsOpen: (state, action: PayloadAction<boolean>) => (doSetContactsOpen(state, action.payload)),
  },
  extraReducers: (builder) => builder
    .addMatcher(
      noteApi.endpoints.deleteNote.matchFulfilled,
      (state, action) => {
        const viewState = isViewed(action.meta.arg.originalArgs, state) ? doCloseView(state) : state;
        return isEdited(action.meta.arg.originalArgs, viewState) ? doCloseEdit(viewState) : viewState;
      },
    ),
});

export const selectApp = (state: RootState): AppState => state.app;
export const {
  openView, openEdit, openDraft, closeView, closeEdit, closeEditOpenView, setContactsOpen,
} = appSlice.actions;
export default appSlice.reducer;
