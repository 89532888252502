import {
  createEntityAdapter, createSlice, EntityAdapter, PayloadAction,
} from '@reduxjs/toolkit';
import type { Feed, FeedState } from './types';
import { isFeedState, removeNoteFromFeeds } from './types';
import type { RootState } from '../../app/store';
import { noteApi } from '../note/noteApi';
import { feedApi } from './feedApi';

const feedsAdapter: EntityAdapter<Feed> = createEntityAdapter();
const FEEDS_STORAGE_KEY = 'feeds';
const loadState = (): FeedState | undefined => {
  const feedsItem = localStorage.getItem(FEEDS_STORAGE_KEY);
  if (feedsItem) {
    const feeds = JSON.parse(feedsItem);
    if (isFeedState(feeds)) {
      return feeds;
    }
  }

  return undefined;
}

const saveState = (state: FeedState) => {
  localStorage.setItem(FEEDS_STORAGE_KEY, JSON.stringify(state));
}

const initialState: FeedState = loadState() || {
  ids: ['1'],
  entities: {
    1: {
      kind: 'feed',
      id: '1',
      query: 'journal',
      isLoading: false,
    },
  },
  serial: 2,
};

export const feedsSlice = createSlice({
  name: 'feeds',
  initialState,
  reducers: {
    add: (state) => {
      const { serial } = state;
      const feed: Feed = { kind: 'feed', id: serial.toString(), isLoading: false };

      feedsAdapter.addOne(state, feed);
      state.serial += 1;
    },
    close: (state, action: PayloadAction<Feed>) => {
      const feed = action.payload;
      feedsAdapter.removeOne(state, feed.id);
    },
    save: (state) => {
      saveState(state);
    }
  },
  extraReducers: ((builder) => builder
    .addMatcher(
      noteApi.endpoints.deleteNote.matchFulfilled,
      (state, action) => removeNoteFromFeeds(state, action.meta.arg.originalArgs),
    )
    .addMatcher(feedApi.endpoints.search.matchPending, (state, action) => {
      const { feedId, searchQuery } = action.meta.arg.originalArgs;
      feedsAdapter.updateOne(state, { id: feedId, changes: { query: searchQuery, noteIds: [], isLoading: true } });
    })
    .addMatcher(feedApi.endpoints.search.matchRejected, (state, action) => {
      const { feedId } = action.meta.arg.originalArgs;
      feedsAdapter.updateOne(state, { id: feedId, changes: { noteIds: [], isLoading: false } });
    })
    .addMatcher(feedApi.endpoints.search.matchFulfilled, (state, action) => {
      const { feedId } = action.meta.arg.originalArgs;
      const noteIds = action.payload;
      feedsAdapter.updateOne(state, { id: feedId, changes: { noteIds, isLoading: false } });
    })
  ),
});

export const { add, close, save} = feedsSlice.actions;
export const { selectById, selectAll } = feedsAdapter.getSelectors();
export const selectFeeds = (state: RootState): FeedState => state.feeds;
export default feedsSlice.reducer;
