import { createSlice } from '@reduxjs/toolkit';
import { ContactState } from './types';
import type { RootState } from '../../app/store';
import { contactApi } from './contactApi';

const initialState: ContactState = {
  create: false,
  contacts: [],
  edit: undefined,
  delete: undefined,
}

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    openCreate: (state) => ({...state, create: true}),
    closeCreate: (state) => ({...state, create: false}),
    openEdit: (state, action) => ({ ...state, edit: action.payload }),
    closeEdit: (state) => ({ ...state, edit: undefined }),
    confirmDelete: (state, action) => ({ ...state, delete: { contact: action.payload, state: 'started' } }),
    cancelDelete: (state) => ({ ...state, delete: undefined }),
  },
  extraReducers: builder => builder
    .addMatcher(contactApi.endpoints?.searchContacts.matchFulfilled, (state, action) =>
      ({...state, contacts: action.payload }))
    .addMatcher(contactApi.endpoints?.getAllContacts.matchFulfilled, (state, action) =>
      ({...state, contacts: action.payload }))
    .addMatcher(contactApi.endpoints?.saveContact.matchFulfilled, (state, action) => ({
      ...state,
        contacts: state.contacts.map((c) => c.id === action.payload.id ? action.payload : c),
        delete: undefined,
      }))
    .addMatcher(contactApi.endpoints?.deleteContact.matchFulfilled, (state) => ({
        ...state,
        contacts: state.contacts.filter(({ id }) => id !== state.delete?.contact.id),
        delete: undefined,
      }))
});

export const { openCreate, closeCreate, openEdit, closeEdit, confirmDelete, cancelDelete } = contactSlice.actions;
export const selectContact = (state: RootState): ContactState => state.contact;
export const isCreateOpen = (state: RootState) => state.contact.create;
export default contactSlice.reducer;
