import { Icon } from 'semantic-ui-react';
import type { AsyncStatus } from '../app/util';
import './StatusIndicator.css';

const mapIndicatorStatus = (isSuccess: boolean, isLoading: boolean, isError: boolean): AsyncStatus => {
  if (isSuccess) {
    return 'fulfilled';
  }
  if (isLoading) {
    return 'pending';
  }
  if (isError) {
    return 'rejected';
  }
  return 'absent';
};

type StatusIndicatorProps = {
  status: AsyncStatus;
};

const statuses: { [status: string]: { icon: JSX.Element; text: string } } = {
  absent: {
    icon: <Icon name="ban" size="big" />,
    text: 'Ehm... we might have misplaced this somewhere.',
  },
  rejected: {
    icon: <Icon name="ban" size="big" />,
    text: 'Something went seriously wrong.',
  },
  pending: {
    icon: <div className="ui active centered inline loader" />,
    text: 'Loading',
  },
};

const StatusIndicator = (props: StatusIndicatorProps): JSX.Element | null => {
  const { status } = props;

  if (status === 'fulfilled') {
    return null;
  }

  const { icon, text } = statuses[status];
  return (
    <div className="status-indicator">
      <div>
        <div>
          {icon}
        </div>
        <div>
          {text}
        </div>
      </div>
    </div>
  );
};

export { mapIndicatorStatus };
export default StatusIndicator;
