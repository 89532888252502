import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getTimeZone } from '../../app/util';
import { addAuthorization } from '../../auth';

const identityApi = createApi({
  reducerPath: 'identityApi',
  tagTypes: ['identity'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: addAuthorization,
  }),
  endpoints: (build) => ({
    validateIdentity: build.query<number, void>({
      query: () => ({
        url: '/identity/validate',
        method: 'GET',
        params: {
          timezone: getTimeZone(),
        },
      }),
      providesTags: () => ([{ type: 'identity' as const, id: 'ME' }]),
    }),
  }),
});

export default identityApi;

export const {
  useValidateIdentityQuery,
  useLazyValidateIdentityQuery,
} = identityApi;
