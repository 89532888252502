import { Button } from 'semantic-ui-react';
import { useEffect } from 'react';
import _ from 'lodash';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { add, save, selectAll, selectFeeds } from './feedsSlice';
import FeedColumn from './FeedColumn';
import './Feeds.css';


const Feeds = (): JSX.Element => {
  const feedsSelector = useAppSelector(selectFeeds);
  const dispatch = useAppDispatch();
  const saveFeedsDebounced = _.debounce(() => {
    dispatch(save())
  }, 2000);

  const feeds = selectAll(feedsSelector);
  useEffect(() => {
    saveFeedsDebounced();
    return saveFeedsDebounced.cancel;
  }, [feeds]);

  const renderedList = feeds.map((feed) => (
    <FeedColumn
      key={feed.id}
      feed={feed}
    />
  ));

  return (
    <div className="feeds">
      {renderedList}
      <div className="add-new">
        <Button icon="plus" onClick={() => dispatch(add())} />
      </div>
    </div>
  );
};

export default Feeds;
