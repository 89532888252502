import {
  Search, SearchProps,
} from 'semantic-ui-react';
import * as React from 'react';
import { useState } from 'react';
import { SearchResultData } from 'semantic-ui-react/dist/commonjs/modules/Search/Search';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectQuickSearch } from './quickSearchSlice';
import { openView } from '../app/appSlice';
import { useLazyGetNoteQuery, useLazyQuickSearchQuery } from '../note/noteApi';
import { Note } from '../note/types';

const QuickSearch = (): JSX.Element => {
  const { loading, results } = useAppSelector(selectQuickSearch);
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState('');
  const [loadNote] = useLazyGetNoteQuery();
  const [quickSearch] = useLazyQuickSearchQuery();

  const onSearchChange = (event: React.MouseEvent<HTMLElement>, data: SearchProps) => {
    setQuery(data.value || '');
    quickSearch(data.value || '');
  };

  const onResultSelected = (event: React.MouseEvent<HTMLDivElement>, data: SearchResultData) => {
    loadNote(data.result.id).then((response) => dispatch(openView((response.data as Note).id)));
  };

  return (
    <Search
      minCharacters={2}
      loading={loading}
      onResultSelect={onResultSelected}
      onSearchChange={onSearchChange}
      results={results}
      value={query}
    />
  );
};

export default QuickSearch;
