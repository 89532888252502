import { SyntheticEvent } from 'react';
import _ from 'lodash';
import { Note } from '../note/types';
import { noteApi } from '../note/noteApi';
import { store } from '../../app/store';

const LOAD_TASKS = 'TASKS';

const editCheckbox = (content: string, taskStart: number, completed: boolean) => {
  const checkboxStart = content.indexOf('[', taskStart);
  const checkbox = content.substring(checkboxStart, checkboxStart + 3);

  if (completed && checkbox === '[ ]') {
    return `${content.substring(0, checkboxStart)}[X]${content.substring(checkboxStart + 3)}`;
  }

  if (!completed && (checkbox === '[x]' || checkbox === '[X]')) {
    return `${content.substring(0, checkboxStart)}[ ]${content.substring(checkboxStart + 3)}`;
  }

  return undefined;
};

const updateTask = (noteId: string, taskId: string, completed: boolean): Promise<void> => {
  const { dispatch } = store;

  return dispatch(noteApi.endpoints.getNote.initiate(noteId)).then((response) => {
    const note = response.data as Note;
    const { id, content } = note;

    const task = note.tasks.find((t) => t.id === taskId);
    if (!task) {
      return;
    }
    const updatedContent = editCheckbox(content, task.startPos, completed);
    if (updatedContent) {
      dispatch(noteApi.endpoints.saveNote.initiate({ id, content: updatedContent, digest: note.meta.digest }));
    }
  });
};

const isTaskInput = (target: EventTarget | null): target is HTMLInputElement => target instanceof HTMLInputElement &&
  target.type === 'checkbox' &&
  target.hasAttribute('data-note-id') &&
  target.hasAttribute('data-task-id') &&
  target.hasAttribute('data-task-completed')


const onTaskChange = (e: SyntheticEvent<EventTarget>) => {
  if (isTaskInput(e.target)) {
    const input = e.target;

    const { noteId, taskId } = _.defaults(input.dataset, { noteId: undefined, taskId: undefined });
    const completed = input.dataset.taskCompleted === "true";
    if (!noteId || !taskId || completed === undefined) {
      return false;
    }

    input.disabled = true;
    updateTask(noteId, taskId, !completed).catch(() => {
      input.checked = !completed;
    }).finally(() => {
      input.disabled = false;
    });

    return true;
  }

  return true;
}

export { LOAD_TASKS, updateTask, onTaskChange };
