import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { addAuthorization } from '../../auth';
import { Completion, Mention } from './types';

export const mentionApi = createApi({
  reducerPath: 'mentionApi',
  tagTypes: ['mention'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: addAuthorization,
  }),
  endpoints: (build) => ({
    getMention: build.query<Mention, string>({
      query: (id) => `/mention/${id}`,
      providesTags: (result) => [{ type: 'mention' as const, id: result?.id }],
    }),
    getMentions: build.query<Mention[], string[]>({
      query: (mentionIds: string[]) => ({
        url: '/mention/',
        method: 'GET',
        params: {
          'id[]': mentionIds,
        },
      }),
      providesTags: (result, error, queryArg) => queryArg.map((id) => ({ type: 'mention' as const, id })),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          const { data: mentions } = await queryFulfilled;
          mentions.forEach((mention) => {
            dispatch(mentionApi.util.upsertQueryData('getMention', mention.id, mention));
          });
        } catch {
          // Nothing to do
        }
      },
    }),
    getMentionCompletions: build.query<Completion[], void>({
      query: () => '/mention/completions',
      providesTags: () => [{ type: 'mention', id: 'COMPLETIONS' }],
    }),
  }),
});

export const {
  useGetMentionQuery,
  useLazyGetMentionQuery,
  useGetMentionsQuery,
  useLazyGetMentionsQuery,
  useGetMentionCompletionsQuery,
} = mentionApi;
